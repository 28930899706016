export const config = {
  // apiDomains: "http://192.168.50.9:8080",
  apiDomains: "https://api.getmyhousing.com",

  defaultMapLocation: {
    latitude: 12.9038631,
    longitude: 77.5855457,
  },
  cookieName: "getmyhousing_user",
  sessionCookie: "getmyhousing_sess",
  preferencesCookie: "getmyhousing_pref",
  leadProvided: "lead_prov",
};
